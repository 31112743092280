/** @jsx jsx */

import { jsx, Box } from "theme-ui"
import React, { Fragment, useEffect } from "react"
import { graphql } from "gatsby"
import {
  ContentPage,
  ContentPageHeader,
  BigContentHeader,
  ContentMetadata,
  ContentMetadataItem,
  TopicPills,
} from "../components/content-page"
import { PageRoot, ContentNode } from "../data/content"
import { NumericDate } from "../data/date"
import { Join } from "../data/list"
import { useTranslation, Trans } from "react-i18next"
import {
  useLocalisedContentViewer,
  useLocalisedContent,
  useLocalisedContentArray,
} from "../components/featured"
import SEO from "../components/seo"
import { Link } from "../components/nav"
import { ArticleLanguageSelector } from "../components/content-page"
import { TextDirectionProvider, useTextDirection } from "../i18n"
import { ActionData } from "../components/actions"
import { ShareData, SocialSharer } from "../components/share"
import { ruLinebreakRegex } from "../data/methods"

const WireArticle: PageRoot<{
  node: ContentNode
  wireDonationBanner: any
  movementDonationBanner: any
}> = ({
  data: { node, wireDonationBanner, movementDonationBanner },
  location,
  pageContext: { locale, locales },
  location: { pathname },
}) => {
  const { t } = useTranslation()
  useLocalisedContentViewer(locale, locales)
  const localisedContent = useLocalisedContent(node, locale)
  const localisedTopic = useLocalisedContent(node.frontmatter.topic, locale)
  const localisedSecondaryTopics = useLocalisedContentArray(
    node.frontmatter.secondaryTopics,
    locale
  )
  let localisedDonationBanner

  let anData = undefined

  if (
    localisedContent.anLink &&
    localisedContent.anIdentifier &&
    localisedContent.anSize &&
    localisedContent.anType
  ) {
    anData = new ActionData(
      localisedContent.anLink,
      localisedContent.anIdentifier,
      localisedContent.anSize,
      localisedContent.anType
    )
  }

  // Get the category name
  const pathArr = location.pathname.split("/")
  const category = pathArr[1]

  // Depending on the category, get content for DonationBanner
  if (category === "movement") {
    localisedDonationBanner = useLocalisedContent(
      movementDonationBanner,
      locale
    )
  } else {
    localisedDonationBanner = useLocalisedContent(wireDonationBanner, locale)
  }

  const dir = useTextDirection(locale)

  let shareData = undefined

  if (
    category === "wire" &&
    location?.href &&
    (localisedContent.shortAbstract || localisedContent.abstract) &&
    localisedContent.title
  ) {
    const shareMessage = localisedContent.shortAbstract
      ? localisedContent.shortAbstract
      : localisedContent.abstract
    shareData = new ShareData(
      shareMessage ? shareMessage : "",
      location?.href,
      localisedContent.title
    )
  }

  useEffect(() => {
    if (locale == "ru") {
      ruLinebreakRegex()
    }
  }, [locale])

  return (
    <TextDirectionProvider locale={locale}>
      <Box sx={{ textAlign: dir.defaultDir }}>
        <SEO
          path={pathname}
          lang={localisedContent.locale}
          title={localisedContent.title}
          description={
            localisedContent.shortAbstract || localisedContent.abstract
          }
          imageUrl={node.frontmatter.featuredImage?.publicURL}
          locales={locales}
        />
        <ContentPageHeader mono node={node}>
          <BigContentHeader
            className="article-content"
            {...localisedContent}
            mono
            content={node}
          />
        </ContentPageHeader>

        <ContentPage
          {...localisedContent}
          content={node}
          anData={anData ? anData : undefined}
          donationBanner={localisedDonationBanner.html}
          meta={
            <ContentMetadata color={node.fields.themeColor}>
              {locales?.length && (
                <ArticleLanguageSelector locale={locale} locales={locales} />
              )}
              {!!node.frontmatter.authors?.length && (
                <ContentMetadataItem
                  label={
                    node.frontmatter.authors.length > 1
                      ? t(`Authors`)
                      : t(`Author`)
                  }
                >
                  <Join
                    value={node.frontmatter.authors.map(a =>
                      a === null ? " " : a.frontmatter.title
                    )}
                  />
                </ContentMetadataItem>
              )}
              {!!localisedContent.translators?.length && (
                <ContentMetadataItem
                  label={
                    <span>
                      {localisedContent.translators?.length > 1
                        ? t(`Translators`)
                        : t(`Translator`)}
                    </span>
                  }
                >
                  <Join
                    value={localisedContent.translators?.map(a =>
                      a === null ? " " : a.frontmatter.title
                    )}
                  />
                </ContentMetadataItem>
              )}
              <ContentMetadataItem label={t(`Date`)}>
                <NumericDate value={node.frontmatter.publishDate} />
              </ContentMetadataItem>
              {node.frontmatter.source?.frontmatter && (
                <ContentMetadataItem label={t(`Source`)}>
                  <Link
                    to={
                      node.frontmatter.originalURL === null ||
                      node.frontmatter.originalURL === undefined
                        ? " "
                        : node.frontmatter.originalURL
                    }
                  >
                    {node.frontmatter.source?.frontmatter?.logo?.publicURL && (
                      <img
                        src={
                          node.frontmatter.source?.frontmatter.logo?.publicURL
                        }
                        sx={{
                          width: "100%",
                          mixBlendMode: "multiply",
                          filter: "grayscale()",
                          opacity: 0.5,
                        }}
                      />
                    )}
                    <span id="original-article-link">
                      <span>
                        {node.frontmatter.source?.frontmatter.title ||
                        node.frontmatter.originalURL ? (
                          <Trans>Original article</Trans>
                        ) : null}
                      </span>
                      {node.frontmatter.originalURL && (
                        <span sx={{ ml: 2 }}>🔗</span>
                      )}
                    </span>
                  </Link>
                </ContentMetadataItem>
              )}
              {shareData && (
                <Box id="social-sharer">
                  <SocialSharer
                    shareData={shareData}
                    type={node.frontmatter.pillar || node.fields.type}
                    sx={{ paddingTop: "15px", paddingBottom: "25px" }}
                  />
                </Box>
              )}
              {localisedSecondaryTopics && localisedSecondaryTopics.length > 0 && (
                <Box>
                  <TopicPills
                    mainTopic={localisedTopic}
                    topics={localisedSecondaryTopics}
                    type={node.frontmatter.pillar || node.fields.type}
                    sx={{ paddingTop: "15px", paddingBottom: "25px" }}
                  />
                </Box>
              )}
            </ContentMetadata>
          }
        />
      </Box>
    </TextDirectionProvider>
  )
}

export const pageQuery = graphql`
  query WireArticle($id: String!) {
    node: markdownRemark(id: { eq: $id }) {
      ...WirePageFragment
    }

    # Movement Donation Banner
    movementDonationBanner: markdownRemark(
      frontmatter: { contentId: { eq: "movement-donation-banner" } }
    ) {
      fields {
        translations {
          childMarkdownRemark {
            html
            frontmatter {
              locale
            }
          }
        }
      }
    }

    # Wire Donation Banner
    wireDonationBanner: markdownRemark(
      frontmatter: { contentId: { eq: "wire-donation-banner" } }
    ) {
      fields {
        translations {
          childMarkdownRemark {
            html
            frontmatter {
              locale
            }
          }
        }
      }
    }
  }
`

export default WireArticle
